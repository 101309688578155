<template>
  <section
    class="AmbulanciasTrasladosForaneos empresas__inner container mt24 mb24"
  >
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">
        Solicitudes de servicios de ambulancias
      </h2>
      <div class="empresas_home__text">
        Aquí podrás encontrar todas tus reservaciones de servicios de
        ambulancias, ya sea servicios especiales, traslados foraneos y traslados
        locales.
        <!-- <br><span style="background:#ecb731">Recuerda que todo servicio de ambulancias deberá ser pagado por lo menos con 48 horas de anticipación para poder apoyarte.</span> -->
      </div>
    </header>
    <v-card flat :disabled="cargando" :loading="cargando">
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="reservaciones"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.fecha="{ item }">
          {{
            $Datetime
              .fromSQL(item.fecha_inicio_eva)
              .setLocale("es-Mx")
              .toLocaleString($Datetime.DATE_HUGE)
          }}
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            :color="_estatusColor(item.estatus_cgo, item.fecha_inicio_eva)"
          >
            <span
              v-html="_formatEstatus(item.estatus_cgo, item.fecha_inicio_eva)"
            />
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item :to="'/empresas/ambulancia/detalle/' + item.id">
                <v-list-item-title v-text="'Información de tu reservación'" />
              </v-list-item>
              <v-list-item
                :href="$apiAdmin + 'pdf/cartacompromiso/' + item.id_eva"
                target="_blank"
                v-if="_canDownload(item.estatus_cgo)"
              >
                <v-list-item-title v-text="'Descargar carta de confirmación'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
export default {
  name: "AmbulanciasListado",
  mixins: [standard, forms],
  components: {},
  data() {
    return {
      cargando: false,
      session: false,
      showTitle: true,
      search: "",
      headers: [
        { text: "FOLIO", value: "folio" },
        { text: "TIPO", value: "tipo_eva" },
        { text: "FECHA", value: "fecha" },
        { text: "LUGAR DE ORIGEN", value: "origen" },
        { text: "Estatus", value: "estatus" },
        { text: "", value: "actions" },
      ],
      reservaciones: [],
    };
  },
  methods: {
    _handleVisibility() {
      this.showTitle =
        "name" in this.$route &&
        this.$route.name &&
        this.$route.name === "AmbulanciasDetalle"
          ? false
          : true;
    },
    _getReservaciones() {
      this._getThings("reservaciones", "reservaciones");
    },
    _formatPlace(string) {
      var datajson = JSON.parse(string);
      return "description" in datajson ? datajson.description : "";
    },
    _canDownload(estatus_cgo) {
      return (
        estatus_cgo === "completo" ||
        this.$session.get("token") === "d0hnR1lTMHcxaC81ZVd0cGl2YU1OUT09"
      );
    },
    _formatEstatus(valor, fecha) {
      let ans = "";
      switch (valor) {
        case "completo":
          ans = "Reservado";
          break;
        case "incompleto":
          const diff = this.$Interval
            .fromDateTimes(this.$Datetime.now(), this.$Datetime.fromISO(fecha))
            .length("hours");
          ans =
            diff > 48
              ? "Pendiente" //"Pendiente<br>de pago"
              : "Cancelado"; //"Cancelado por<br>falta de pago";
          break;
        case "cancelado":
          ans = "Cancelado";
          break;
        default:
          ans = "";
          break;
      }
      return ans;
    },
    _estatusColor(valor, fecha) {
      const estatus = this._formatEstatus(valor, fecha);
      switch (estatus) {
        case "Reservado":
          return "#56a0d3";
          break;
        case "Pendiente":
          return "#ecb731";
          break;
        case "Cancelado":
          return "#FF6E4E";
          break;
        default:
          return "transparent";
          break;
      }
    },
  },
  created() {
    // this._handleVisibility();
    this._getReservaciones();
  },
  watch: {
    $route(a, b) {
      // this._handleVisibility();
    },
  },
};
</script>
