var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"AmbulanciasTrasladosForaneos empresas__inner container mt24 mb24"},[_c('ButtonBackHome'),_vm._m(0),_c('v-card',{attrs:{"flat":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.reservaciones,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$Datetime .fromSQL(item.fecha_inicio_eva) .setLocale("es-Mx") .toLocaleString(_vm.$Datetime.DATE_HUGE))+" ")]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm._estatusColor(item.estatus_cgo, item.fecha_inicio_eva)}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm._formatEstatus(item.estatus_cgo, item.fecha_inicio_eva))}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('more_vert')}})],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{attrs:{"to":'/empresas/ambulancia/detalle/' + item.id}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Información de tu reservación')}})],1),(_vm._canDownload(item.estatus_cgo))?_c('v-list-item',{attrs:{"href":_vm.$apiAdmin + 'pdf/cartacompromiso/' + item.id_eva,"target":"_blank"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Descargar carta de confirmación')}})],1):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"empresas__header"},[_c('h2',{staticClass:"empresas_home__heading heading"},[_vm._v(" Solicitudes de servicios de ambulancias ")]),_c('div',{staticClass:"empresas_home__text"},[_vm._v(" Aquí podrás encontrar todas tus reservaciones de servicios de ambulancias, ya sea servicios especiales, traslados foraneos y traslados locales. ")])])}]

export { render, staticRenderFns }